import React from 'react'

function Career() {
  return (
    <div>
      <h1>Our Career</h1>
    </div>
  )
}

export default Career

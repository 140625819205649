import React, { useState } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Input,
    Button,
    FormLabel,
    Checkbox,
    FormControl,
    useToast,
  } from '@chakra-ui/react'
import { Navigate, useLocation, useNavigate } from 'react-router';
import {useAuth } from './auth/authenticate' 
import { useAuthContext } from './auth/Auth'; 
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';



function Login() {
  const toast = useToast();
    const navigate = useNavigate();
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState(''); 
   const [btnLoading,setBtnLoading]= useState(false);
  
  const { state } = useLocation();
  const handleLogin = async () => {
    setBtnLoading(true);
    if(email==''||password==''){
      toast({
        title:'Please Enter all fields', 
        status: 'warning',
        duration: 2000,
        isClosable: true,
      })
      setBtnLoading(false);
      return ;
    }
		const body = {
			email: email,
			password: password,
		}; 
		try {
			const resp = await axios.post('https://api.ekanaherbs.com/api/v1/login', body,  {
        "Content-Type": "application/json",
        "Accept": "application/json", 
      },);
			if (resp.data.statusCode === 200) {
        setBtnLoading(false);
        toast({
          title:"Logged in Successfully", 
          status: 'success',
          duration: 2000,
          isClosable: true,
          position:'top'
        })
        localStorage.setItem('token',resp.data.token)
       window.location.href='/dashboard';
			}else{
        setBtnLoading(false)
      console.warn(resp.data)
        toast({
          title:resp.data.error, 
          status: 'warning',
          duration: 2000,
          isClosable: true,
        })
      }
		} catch (error) {
      setBtnLoading(false) 
        toast({
          title: `Failed to login`, 
          status: 'warning',
          duration: 2000,
          isClosable: true,
        })
			 
		}
	};
  return (
    <>
              <img src='https://static.vecteezy.com/system/resources/thumbnails/005/879/539/small/cloud-computing-modern-flat-concept-for-web-banner-design-man-enters-password-and-login-to-access-cloud-storage-for-uploading-and-processing-files-illustration-with-isolated-people-scene-free-vector.jpg' style={{margin:'auto'}}  />
              <h5 style={{marginTop:'10px'}}>Login with your email and password. </h5>
              <FormControl> 
              <FormLabel>Customer Id</FormLabel>
              <Input type='text' placeholder='Please Enter your customer id'  onChange={(e)=>setEmail(e.target.value)}/>
              </FormControl>
              <FormControl> 
              <FormLabel>Password</FormLabel>
              <Input type='text' onChange={(e)=>setPassword(e.target.value)} placeholder='Please Enter your password'/>
              </FormControl>
              <br/>
              <p className='text-muted d-flex justify-content-end'>Don't Have Account <Link to="/register"> <span style={{color:'indigo',marginInline:'5px',fontWeight:'bold'}}> Register Here</span></Link>  </p>
               <br/>
              <div className='text-center'>
                <div style={{marginBlock:'10px'}}>
                <Checkbox defaultChecked>Remember Me</Checkbox>
                </div>
                 <Button colorScheme='orange' isLoading={btnLoading} isDisabled={btnLoading} loadingText={<>Please Wait</>} onClick={()=>handleLogin()} >Login Me</Button>
              </div>
             
              </>
  )
}

export default Login

import React from 'react'
import ProductBanner from './ProductBanner'

function ProductBanners() {
  return (
    <div className='product__banners'>
      
      <ul>
        <li>
          <ProductBanner image="https://ekanaherbs.com/custom/images/1-final1.jpg" text="Support your organ health and immunity daily with our S-46 ingredients"/>
        </li>
        <li>
          <ProductBanner  image="https://ekanaherbs.com/custom/images/life-elixir.jpeg"     text="Life Elixir Juice helps detoxify your body and is beneficial for the stomach and skin."/>
        </li>
        <li>
          <ProductBanner  image="https://ekanaherbs.com/custom/images/2final.jpg" text="The best way to keep your heart healthy is through proper nutrition, adequate sleep, avoiding stress, and consuming Ekardio Booster daily."  />
        </li>
        <li>
          <ProductBanner  image="https://ekanaherbs.com/custom/images/3-final.jpg"   text="To manage diabetes, consume Sugar-Ease regularly."/>
        </li>

      </ul>
    </div>
  )
}

export default ProductBanners

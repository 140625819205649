import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import UnderDevelopment from '../../Products/UnderDevelopment'

function ShippingProcess() {
  return (
    <div className='App'>
      <Header/>
        <div className='text-center main__section'>
            <h1>Shipping Process</h1>

            <UnderDevelopment/>
        </div>
      <Footer/>
    </div>
  )
}

export default ShippingProcess

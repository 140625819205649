import { Button, Link } from '@chakra-ui/react'
import React from 'react'

function OurMission() {
  return (
    <div className='row d-flex justify-content-center'>
    <div className='col-md-auto'>
      <img style={{height:'500px'}} src='https://i.ibb.co/CbXvKCj/vsn.png'/>
    </div>
    <div className='col-md-6 align-items-center' style={{paddingTop:'100px'}}>
      <h1 className='col-md-12' style={{fontWeight:'bolder'}}>Our Mission</h1>
      <h4 className='col-md-12'>Empowering global households with Ayurveda, breaking direct selling records, eradicating disease and unemployment, creating prosperity worldwide. </h4> 
<p>Our mission is to reach Ayurveda to every household in the world and We aim to set a record in the direct selling industry all over
the world while also eradicating the burden of disease and unemployment in India. We are committed to creating a better, more
prosperous future for all. Our goal is to empower people, particularly in India, to become independent and create sustainable
livelihoods. We are confident that our efforts will make a positive and lasting impact in the lives of the people we serve a strong
employment platform for more and more people so that all the partners can fulfill all their dreams from this platform.</p>
<br/>
<Button colorScheme='teal'><Link to="/start-a-bussines">Read More</Link> </Button>
    </div>
    
    
  </div>
  )
}

export default OurMission

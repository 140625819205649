import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../components/ExampleCarouselImage';
import TestimonialCard from './TestimonialCard';
 

function TestimonialCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
      <TestimonialCard imageName='Mr. Jyoti Prasad borah' image='https://ekanaherbs.com/uploads/showcases/230608183428WhatsApp%20Image%202023-06-08%20at%2011.59.00%20PM.jpeg' desc="I greet you all on behalf of j p bohara Assam. I found ekana herbs products and marketing plan very good. I have full faith in ekana herbs management." />


      </Carousel.Item>
      <Carousel.Item>
      <TestimonialCard  imageName='Dr. Manoj Manjani' image='https://ekanaherbs.com/uploads/showcases/230608183953manoj%20manjani.jpeg' desc="I Dr. Manoj Manjani practice in Kanpur I love Ekana herbs products I am getting very good feedback from my patients for using Ekana herbs product, I love Ekana herbs."  />
        
        
      </Carousel.Item>
      <Carousel.Item>
        <TestimonialCard   imageName='Mr. Anand Sood & Mrs. Urmil Sood' image='https://ekanaherbs.com/uploads/showcases/230608184649tau%20ji.jpeg' desc="I am Anand Sood, my age is about 76 years, I have been drinking Ekana herbs S-46 juice for only a few days, I feel very energetic even at this age, both I and my wife Urmil Sood drink it till now. Best health drink I have ever had." />
        
        
      </Carousel.Item>
    </Carousel>
  );
}

export default TestimonialCarousel;
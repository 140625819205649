import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Error404 from '../../Products/Error404'
import UnderDevelopment from '../../Products/UnderDevelopment'

function RefundPolicy() {
  return (
    <div className='App'>
      <Header/>
      <div className='main__section'>
        <h1>Refund Policy</h1>

         <UnderDevelopment/>
      </div>
      <Footer/>
    </div>
  )
}

export default RefundPolicy

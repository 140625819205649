import React from 'react'

const ProductBanner = (props) => {
  return (
    <div className='product__banner'>
        <img src={props.image}/>
        <div className='text_container'>
            <h3>{props.text}</h3>
            <button className='banner_explore_button'>Explore</button>
        </div>
      
    </div>
  )
}

export default ProductBanner

import { ArrowBackIcon, ArrowForwardIcon, MinusIcon, PlusSquareIcon } from '@chakra-ui/icons'
import { Button, CloseButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

function CartItem(props) {
  const toast = useToast();
  
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [addedQuan,setAddedQuan]=useState(0);
  
  const handleQuanIncrementor=(product)=>{
    if(addedQuan<40){

setAddedQuan(addedQuan+1);
const cart = JSON.parse(localStorage.getItem('cart')) || [];
   
const foundIndex = cart.findIndex(item => item.product === product.id);

if (foundIndex !== -1) { 
    cart[foundIndex].quantity += 1; 
} else { 
    const newItem = {
        product: product.id,
        quantity: 1, 
    };
    cart.push(newItem);
}
localStorage.setItem('cart', JSON.stringify(cart));
    toast({
      title: `Added 1 more quanity of ${product.product_title}`, 
      status: 'success',
      duration: 1000,
      isClosable: true,
    })
    }else{
      toast({
        title: `Cannot Add more than 40`, 
        status: 'warning',
        duration: 1000,
        isClosable: true,
      })
    }
    
    
  }

  const [cartItems, setCartItems] = useState([]);
  const handleRemoveFromCart = (productId) => {
  // Filter out the item to be removed based on its productId
  const updatedCart = cartItems.filter(item => item.product !== productId);
  
  // Update the local storage and state
  localStorage.setItem('cart', JSON.stringify(updatedCart));
  setCartItems(updatedCart); 
  onClose()
};
   

 
     
  const handleQuanDecrementor=(product)=>{
    if(addedQuan>1){
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
   
const foundIndex = cart.findIndex(item => item.product === product.id);

if (foundIndex !== -1) { 
    cart[foundIndex].quantity -= 1; 
} else { 
    const newItem = {
        product: product.id,
        quantity: 1, 
    };
    cart.push(newItem);
}
localStorage.setItem('cart', JSON.stringify(cart));
      setAddedQuan(addedQuan-1);

      toast({
        title: `Removed 1  quanity of ${product.product_title}`, 
        status: 'success',
        duration: 1000,
        isClosable: true,
      })
    }else{
      toast({
        title: `Cannot Remove`, 
        status: 'warning',
        duration: 1000,
        isClosable: true,
      })
    }
    
     
  }
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || []; 
    setCartItems(cart)
    const found = cart.find(item => item.product === props.product.id);
    if (found) {
      setAddedQuan(cart.find(item => item.product === props.product.id).quantity) 
    }
  }, []);
  return (
    <div className='py-1'>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(10deg)'
    />
        <ModalContent>
          <ModalHeader>
            Remove From Cart</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text style={{fontWeight:'bolder',fontSize:'30px'}}>Are you sure you want to remove {props.product.product_title} from cart</Text>
          </ModalBody>
          <ModalFooter>
            <Button   onClick={()=> handleRemoveFromCart(props.kid)}>Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
       
    
    <div  className='row d-flex  align-items-center' style={{backgroundColor:'#f4f4f4'}}>
    <div className='col-md-12 d-flex justify-content-end' style={{float:'right'}}>
       <CloseButton onClick={()=>onOpen()}/> 
      </div>
      <div className="col-md-3 col-sm-6 col-6 cartItem">
        <img src={`https://ekanaherbs.com/custom/images/products/new/${props.product.images.split(',')[0].replace('-','/')}`} style={{height:150,width:150, borderRadius:'50%'}}/>
      </div>
      <div className='col-md-3 col-sm-6 col-6 text-left'>
                <p style={{fontWeight:'bolder'}}>
                    {props.product.product_title} 
                </p>
                <p style={{fontWeight:'bolder'}}>
                {props.product.category_name}
                </p>
                <p style={{fontWeight:'normal'}}>
                    Quantity :  {props.quantity} Unit(s)
                </p>
                
            </div>
      <div className='col-md-6'>
        <div className='row d-flex justify-content-center align-items-center'>
            
            
            <div className='col-md-6 addSubs'  >
                 <ArrowBackIcon boxSize={6} onClick={()=>handleQuanDecrementor(props.product)}  /> 
                  
                 <span className='quan'>{addedQuan}</span><ArrowForwardIcon boxSize={6}  onClick={()=>handleQuanIncrementor(props.product)}  />
            </div>
            <div className='col-md-6'>
                <span style={{fontSize:'25px',fontWeight:500}}>₹ {addedQuan*props.product.product_price}/-</span> 
            </div>
        </div>
      </div>


      
      
    </div>
     

    
    </div>
  )
}

export default CartItem

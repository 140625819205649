import React, { useRef } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import becebo from '../../src/images/becebo.png'
import f1 from '../../src/images/f1.png'
import f2 from '../../src/images/f2.png'
import f3 from '../../src/images/f3.png'
import f4 from '../../src/images/f4.png'
import f5 from '../../src/images/f5.png'
import ImageHeader from '../components/ImageHeader'
import ebo from '../images/ebo.jpg'
import opportunities from '../images/opportunity.jpg'
import mp from '../images/mp.jpg'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'


function StartABussines() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = React.useRef(null)
  const incomes = [
    ['40', 'Life time discount on mrp'],
    ['20', 'Start up bonus'],
    ['5', 'Repurchase Power bonus'],
    ['20', 'Team  Build Bonus'],
    ['5', 'Team Maintain Bonus'],
    ['5', 'Fitness Assured Fund'],
    ['7', 'Accelerator Bonus'],
    ['3', 'Child Education Fund'],
    ['10', 'Speed Bonus'],
    ['5', 'Lifestyle bonus'],
    ['5', 'Royality Club'],
];

  return (
    <div className='App'>
        <Header/>
      <div className='main__section'>
      <div className='smooth__header smooth__header__info  '>
        <p style={{fontWeight:'bolder'}}> I N D E P T H</p>
           <h4>WHY EKANA HERBS</h4>
      </div>
      <div className='row d-flex justify-content-center'>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f1} style={{height:'100px'}} className='m-auto' />
         <p className='text-center fw-bolder'>Experienced Profile</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f2} style={{height:'90px'}} className='m-auto' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >Well designed competition open</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f3} style={{height:'90px'}} className='m-auto' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >We belive in  transparency</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f4} style={{height:'90px'}} className='m-auto ' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >We delivered high quality products</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f5} style={{height:'90px'}} className='m-auto' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >Ekana academy work for a better future of associates</p>
        </div>
      </div>
      <div className='text-center m-auto'>
          <h3 style={{marginTop:'60px'}}>Become An EBO  (Ekana Bussines Owner) </h3>
          <img src={becebo} className='m-auto'/>  

      </div>


      <br/>
      <div className='smooth__header smooth__header__info  '>
        <p style={{fontWeight:'bolder'}}>G R O W T H </p>
           <h4>BECOME🤝BELIEVE🤝BUILD</h4>
      </div>

      <div className='text-center m-auto container'>
          <h3 style={{marginTop:'60px'}}>Become An EBO  (Ekana Bussines Owner) </h3>
          
          <div className='content'>
           <p>At Ekana Herbs, we believe that nature has the power to heal and restore. We are dedicated to bringing you the highest quality herbal supplements that harness the power of nature to support your health and wellbeing.
            </p> 
            <p>
            With a commitment to sustainability and ethical sourcing, we believe that we can create a healthier world for all.  
            </p>
           <p>
            At Ekana Herbs, we are building a community of people who are passionate about health and wellness.
           </p>
           <p>
            Our network marketing model allows individuals to build their own businesses while sharing our high-quality products with others. We provide training and support to help our members succeed, and we are dedicated to creating a culture of collaboration and empowerment.
           </p>
           <p>
            The benefits of Ekana Herbs' products are clear: natural, high-quality supplements that support your health and wellbeing. But the benefits don't stop there. As a member of our community, you will have the opportunity to build your own business, create financial independence, and make a positive impact on the world. With Ekana Herbs, you can benefit your body, your mind, and your wallet.
           </p>
 
  
          </div> 

           
       

      <ImageHeader bgColor='#ccefcb' color="#077004" text="Ekana Bussines Opportunity" image={opportunities}/>
      <div className='content__bg'>
      <p>
  Join the Ekana Herbs family and discover an opportunity to achieve financial independence while promoting health and wellness. As a member of our network marketing community, you can build your own business and share our high-quality herbal supplements with others. With a commitment to sustainability and ethical sourcing, Ekana Herbs offers a unique opportunity to make a positive impact on the world while creating the lifestyle you desire.
</p>
<p>
  As an Ekana Herbs member, you will receive training and support to help you succeed, and have access to a range of benefits including discounts, bonuses, and the opportunity to participate in our income plan. With a low start-up cost and the flexibility to work from anywhere, Ekana Herbs offers a truly empowering opportunity to transform your life.
</p>
<p>
  Don't miss out on this exciting opportunity to join the Ekana Herbs family and build the business of your dreams. Contact us today to learn more and get started!
</p>


  
  </div>
  <ImageHeader bgColor='#ccefcb' color="#077004" text="Marketting Plan" image={mp}/>
      <div className='content__bg'>
      <p>
  Ekana Herbs' income plan offers a variety of bonuses and incentives for members to earn money and build their own businesses. With a focus on team building and maintaining consistent performance, members can earn up to 40% lifetime discount on Mrp, 5% repurchase power bonus, 20% team build bonus, 5% team maintain bonus and fitness assured fund, 7% accelerator bonus and 3% child education fund, 10% speed bonus, 5% lifestyle bonus, and 5% royalty club bonus. These bonuses are based on a matching system that rewards team performance and total company PV turnover, with monthly and half-yearly payout durations.
</p>
      </div>
       
       </div>

       <div className='text-center m-auto'>
          <h3 style={{marginTop:'60px'}}>Types of Income  At EKANA HERBS </h3> 
        <br/>

      </div>
      <div className='row d-flex justify-content-start'>
        {incomes.map((income)=>(
          <div className='col-md-3 col-sm-6 col-xs-6 text-center py-2'>
           <div className='percent__box m-auto' onClick={onOpen}>
           {income[0]}%
            </div>
            <p className='text-center'>{income[1]}</p>
            </div>
        ))}
      </div>
        
      </div>
      <Footer/>

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bussines Type Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
             
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue'  onClick={onClose}>
              Close
            </Button>
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
} 
export default  StartABussines

import { Link, useNavigate } from "react-router-dom"; 
import Header from "../Header/Header"; 
import { useAuthContext } from "./Auth";
import { Button } from "react-bootstrap";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, ButtonGroup, IconButton } from "@chakra-ui/react";
import CTA from "../Body/CTA";
import Footer from "../Footer/Footer";
const logout=()=>{
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location.reload()
}
export default function AuthHome(props) {
  const { user,keyValue} =useAuthContext();
  const navigate = useNavigate();

  

  return (<>
     <div className="AppName">
      <Header/>
      <div className="main__section">
        <div className="home__header">
           <h2>My Dashboard</h2> 
           <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="#">Portal</a></li>
    <li className="breadcrumb-item active" style={{color:'peachpuff'}} aria-current="page">Dashboard</li>
  </ol>
        </div>
        <div className="container">
         <div className="row d-flex">
          <div className="col-md-3">
            <div className="info mb-4">
              <h4 style={{margin:'0px',padding:'0px'}}>Your Info</h4>
              <hr style={{color:'white',margin:'2px',padding:'0px'}}/>
              <ul className="">
              <li>Name</li>
              <li>Mobile</li>
              <li>Email Id</li>
              <li>Address</li>
              <li>GST Address</li>
            </ul>
            <hr style={{color:'white',margin:'2px',padding:'0px'}}/>
            <div className="text-center mt-2">
               <Button variant="warning" style={{margin:'auto'}}> <EditIcon/>Update Profile</Button>
            </div>
           
            </div>

            <div className="info">
              <h4 style={{margin:'0px',padding:'0px'}}>Current Shiping Details</h4>
              <hr style={{color:'white',margin:'2px',padding:'0px'}}/>
              <ul className="">
              <li>Full Address</li>
              <li>State</li>
              <li>Country</li>
              <li>Pincode</li>
              <li>Reciever Mobile Number</li>
            </ul>
            <hr style={{color:'white',margin:'2px',padding:'0px'}}/>
            <div className="text-center mt-2">
               <Button variant="warning" style={{margin:'auto'}}> <EditIcon/>Update Details</Button>
            </div>
           
            </div>
          
          </div>
          <div className="col-md-9">
          {/* <Alert status='success'>
    <AlertIcon />
    Your Last Product has been shipped successfully , for more details visit  <Link to=""><span style={{marginInlineStart:'7px',color:'black',fontWeight:'bolder'}}> here</span></Link> .
  </Alert> */}
{/* <br/> */}
              <div className="row d-flex">
                <div className="col-md-3 p-2">
                  <div className="card text-center p-3 py-4">
                    <h1>0</h1>
                    <h4 className="text-center">Arrived</h4>
                  </div>
                   
                </div>
                <div className="col-md-3 p-2">
                  <div className="card text-center p-3 py-4">
                    <h1>0</h1>
                    <h4 className="text-center">In-Transit</h4>
                  </div>
                   
                </div>
                <div className="col-md-3 p-2">
                  <div className="card text-center p-3 py-4">
                    <h1>0</h1>
                    <h4 className="text-center">Tickets</h4>
                  </div>
                   
                </div>
                <div className="col-md-3 p-2">
                  <div className="card text-center p-3 py-4">
                    <h1>0</h1>
                    <h4 className="text-center">Invoices</h4>
                  </div>
                   
                </div>
              </div>
              <br/>
           

          <div className="col-md-12   text-left mb-3">
          <div className="card">
  <h5 className="card-header">Product Orders   </h5>
  <div className="card-body">
    <h5 className="card-title">Your all orders will appear here will appear here</h5>
    <img src="https://cdn-icons-png.flaticon.com/128/5089/5089733.png"  style={{margin:'auto'}}/>
    <br/>
    <p className="card-text text-center fw-bold my-3">No Orders Found</p>
   
  </div>
          </div>
          </div>

          <div className="col-md-6   text-left mb-3">
          <div className="card">
  <h5 className="card-header">Recent Tickets <span style={{float:'right'}} > <Link to="/dashboard/add-ticket"><AddIcon/></Link>  </span>  </h5>
  <div className="card-body">
    <h5 className="card-title">Your all tickets will appear here</h5>
    <p className="card-text">Please rasise a ticket only if neccessary</p>
   
  </div>
          </div>
          </div>
          </div>
          <Button variant="light" onClick={()=>logout()}>Logout</Button>
         </div>
        </div>

       
       
        <CTA/>
        
      </div>
      
     </div>
       <Footer  /> </>
  );
}
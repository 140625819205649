
import React from 'react'
import { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import axios from '../auth/defaultr-axios';
 
 

function Category() {
  const [categories, setCategories] = useState([]);
  const searchCat = (cat) => { 
    // Redirect to the products page with the search query
    window.location.href = `/products?category=${cat}`;
  }; 

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);
  

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.ekanaherbs.com/api/v1/categories');
      setCategories(response.data); // Assuming the data is an array
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className='categories' >
      <h2>Our Product Categories</h2>
      <ul>
        {categories.map((category) => (
        
        <li onClick={()=>searchCat(category.category_name)} className='hfont' key={category.id}>
        <img src={`https://ekanaherbs.com/custom/images/products/${category.category_image}`} alt="Category Image" />
            <p style={{textAlign:'center'}}>{category.category_name}</p>
             </li>
           
              
        ))}
      </ul>
    </div>
  )
}

export default Category

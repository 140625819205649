import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import Sidebar from './Sidebar';
import AboutComponent from './AboutComponent'; 
import OurBrand from './OurBrand';
import OurJourney from './OurJourney';
import Vision from './Vision';
import OurMission from './Mission';
import Career from './Career';
import Faqs from './Faqs';
import BussinesComponent from './BussinesComponent';

function AboutUs() {
  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const lastSegment = pathnameSegments[pathnameSegments.length - 1];

   // Empty dependency array ensures that the effect runs once on mount
   const pathNameMapping = {
    '/about-ekana/about': 'About Ekana',
    '/about-ekana/vision': 'Vision',
    '/about-ekana/mission': 'Mission' 
  }; 
   
  return(
    <div className='App' >
         <Header/>
     
     <div className='main__section'>
         
   
   
     
      
        <div className='container'>
         <ul className='action-ul'>
         {Object.entries(pathNameMapping).map(([path, name]) => (
          <li key={path} className={lastSegment === path.split('/').pop() ? 'clickable active' : 'clickable'}>
            <Link to={path}>{name}</Link>
          </li> ))}
          </ul>    
        {lastSegment === 'about' && <AboutComponent />} 
        {lastSegment === 'start-a-bussines' && <BussinesComponent />}   
        {lastSegment === 'our-brand' && <OurBrand />} 
        {lastSegment === 'our-journey' && <OurJourney />} 
        {lastSegment === 'vision' && <Vision />} 
        {lastSegment === 'mission' && <OurMission />} 
        {lastSegment === 'career' && <Career />} 
        {lastSegment === 'faqs' && <Faqs />} 
                

        </div>
        
        <Footer/>
      
     </div>
      

    </div>
  )
}

export default AboutUs

import React from 'react'
import e404 from '../images/e404.png'

function UnderDevelopment() {
  return (
  <div style={{'display':'flex','flexDirection':'column','justifyContent':'center'}}>
     
    <img src='https://cdn-icons-png.flaticon.com/128/7069/7069673.png'  style={{'height':'300px','width':'300px','marginInline':'auto'}}/>
    <h2 style={{'marginTop':'40px','fontSize':'32px','padding':'12px'}}>Page Under Development</h2>
  </div>
  )
}

export default UnderDevelopment

import React, { useEffect, useRef, useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import axios from 'axios';
import RemoveIcon from '../svgs/RemoveIcon';
import LoadingPage from '../Products/LoadingPage';
import Error404 from '../Products/Error404';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Input,
  Button,
  FormLabel,
  Checkbox,
  FormControl,
} from '@chakra-ui/react'
import { 
  useToast,
} from '@chakra-ui/react'
import { ArrowForwardIcon, EditIcon } from '@chakra-ui/icons';
import CartItem from './CartItem';
import OfferItem from '../Body/OfferItem';
import OfferCartItem from './OfferCartItem';
import { Link } from 'react-router-dom';
import Login from '../Login';
import RegisterPage from '../Pages/RegisterPage';
import Register from '../Register';
import { useAuthContext } from '../auth/Auth';
 
function MyCart() {
  
  const toast = useToast()
  const [authPage,setAuthPage]=useState('login');
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const [mycart,setMyCart]=useState([]);
  const [products , setProducts]= useState([]);
  const [offers , setOffers]= useState([]);
  const [loading , setLoading] = useState(true)
  const [productPrice,setProductPrice]= useState(0);
  const [tax,setTax]=useState(0);
  const signin= useAuthContext();
  const fetchData = async () => {
    try {
     
      const responseOfProducts = await axios.get('https://api.ekanaherbs.com/api/v1/all-products');
      setProducts(responseOfProducts.data);
      const responseOfOffers = await axios.get('https://api.ekanaherbs.com/api/v1/all-offers');
      setOffers(responseOfOffers.data);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };
  const handleQuantityChange = (productId, event) => {
    const updatedCartCopy = [...mycart];
    const index = updatedCartCopy.findIndex((item) => item.product === productId);
    if(parseInt(event.target.value)<1 || parseInt(event.target.value)>40){
      toast({
        title: 'Invalid Value Please enter quantity from 1 to 40.', 
        status: 'warning',
        duration: 2000,
        position:'top',
        isClosable: true,
      })
      return ;
    }
    if (index !== -1) {
      updatedCartCopy[index].quantity = event.target.value;
      setMyCart(updatedCartCopy);
    }
  };
  useEffect(() => {
    // Fetch data when the component mounts
    fetchData() 
     
    
     
     
  }, []);
  useEffect(()=>{
    const carts = JSON.parse(localStorage.getItem('cart')) || [];
    let price=0;
    setMyCart(carts);
    let gst=0;
    carts.forEach(element => {
      const product = products.find((prod) => prod.id === element.product && element.type=="product"); 
      if (product) {
        const productPrice2= product.product_price;
        const gstPerc = product.gst;
        price +=element.quantity*productPrice2;
        gst += parseInt(productPrice2*gstPerc*element.quantity/100)
      }
      const offer = offers.find((prod) => prod.id === element.product && element.type==="offer");
      if(offer){
        const productPrice2= offer.offer_price;
        const gstPerc = 18;
        price +=element.quantity*productPrice2;
        gst += parseInt(productPrice2*gstPerc*element.quantity/100)
      }
      
    });
    setProductPrice(price);
    setTax(gst);
  
  },[mycart])
   
  const removeFromCart = (productIdToRemove) => {
    const updatedCart = mycart.filter((item) => item.product !== productIdToRemove);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setMyCart(updatedCart);
    // console.log(productIdToRemove)
 
   
  };
  return (
    
    <div className='App'>
     <Header/>
       <div className='main__section container'>
       <div className='bradcrumb'>Home / <strong>Cart</strong>
       </div>
      <div className='smooth__header smooth__header__info  '>
           <h4>Your Cart</h4>
           <div className='search__container'>
            
           </div>

            
      </div> 
      {loading && <LoadingPage />}
      {!loading && products.length <1 && <Error404/>}
      {!loading && mycart.length<1 && (<div className='text-center m-auto'><img style={{margin:'auto'}} src="https://cdn-icons-png.flaticon.com/512/11329/11329060.png"/> <p style={{fontSize:'30px',textAlign:'center'}}>Your Cart Is Empty</p></div>)}
      <div class="row d-flex">
        <div class="col-md-8">
        
         { products.length>0  && offers.length>0 &&  mycart.map((carts,key)=>(
            carts.type=="product"?    
                (<CartItem kid={carts.product} key={key} quantity={carts.quantity}  product={products.find((product)=>product.id==carts.product)}/>):<OfferCartItem  kid={carts.product}  key={key} quantity={carts.quantity}  offer={offers.find((offer)=>   carts.product===offer.id && carts.type==="offer" )}/>
              ))}
            
        </div>
         {mycart.length>0?(<div class="col-md-4" id="cartSummary">
        
        <div style={{backgroundColor:'#f4f4f4',minHeight:'400px',padding:'10px'}}>
          <h3>Cart Summary</h3>
          <hr/>

          <p style={{marginInline:'10px'}} >Product Price <span style={{float:'right'}}>₹{parseInt(productPrice)}</span></p>
          
          <p style={{marginInline:'10px'}} >Discount <span style={{float:'right'}}>₹0</span></p>
          <p style={{marginInline:'10px'}} >Shipping Charge <span style={{float:'right'}}>₹{productPrice>999?100:0}</span></p>
          <p style={{marginInline:'10px'}} >Round off <span style={{float:'right'}}>₹0.00</span></p>
          <p style={{marginInline:'10px'}} >Price before tax <span style={{float:'right'}}>₹{parseInt(productPrice-tax)}</span></p>
          <p style={{marginInline:'10px'}} >Tax values <span style={{float:'right'}}>₹{parseInt(tax)}</span></p>
          <hr/>
          <p style={{marginInline:'10px'}} >Total Price <span style={{float:'right',fontWeight:'bolder',color:'limegreen',fontSize:'30px'}}>₹{parseInt(productPrice+(productPrice>999?100:0))}</span></p>

          <button ref={btnRef} onClick={()=>onOpen()} type='button' className='checkout__button'>Checkout</button>
        </div> 
      </div>) :''}

      {/* Drawer For Account    */}
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'md'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Complete Your Checkout</DrawerHeader>

          <DrawerBody>
            {signin.user==null?
            <>
            <ul className='auth_options' style={{display:'flex',justifyContent:'center'}}>
              <li className={authPage=='login'?'active':''} onClick={()=>setAuthPage('login')}>Login </li>
              <li className={authPage=='signup'?'active':''}  onClick={()=>setAuthPage('signup')}>Signup</li>
              </ul> 
              {authPage=='login'?(<Login/>):<Register/>}</>:<><br/><br/><br/><div className='text-center'>
  <h1>Payment Gateway Page</h1>
  <p style={{textAlign:'center'}}>We are currently working on payment gateway integration. Thank you for your patience.</p>
  <br/>
  <img src="https://cdn-icons-png.flaticon.com/128/4108/4108042.png" style={{margin:'auto'}}/>
</div></>}
          </DrawerBody>

          
        </DrawerContent>
      </Drawer>

      </div>
        
        
          
          
          
          
       </div>
      <Footer/>
    </div>
  )
}

export default MyCart

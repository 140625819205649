 
import './App.css';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import { BrowserRouter, Routes , Route, useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';
 
import Home from './Pages/Home';
import ProductsPage from './Pages/ProductsPage';
import BrandsPage from './Pages/BrandsPage';
import OffersPage from './Pages/OffersPage'; 
import EkanaAcademy from './Pages/EkanaAcademy';
import ContactUs from './Pages/important_links/ContactUs';
import UserAgreement from './Pages/important_links/UserAgreement';
import TermsConditions from './Pages/important_links/TermsConditions';
import PrivacyPolicy from './Pages/important_links/PrivacyPolicy';
import ShippingProcess from './Pages/quick_links/ShippingProcess';
import Faq from './Pages/quick_links/Faq';
import SingleProducts from './Products/SingleProducts';
import AboutUs from './Pages/About/AboutUs';
import RefundPolicy from './Pages/important_links/RefundPolicy';
import MyCart from './Cart/MyCart';
 
import { ChakraProvider } from '@chakra-ui/react'
import Resources from './Pages/Resources';
import StartABussines from './Pages/StartABussines';
import Contact from './Pages/Contact';
import Iso from './Pages/Iso';
import Fssai from './Pages/Fssai';
import AuthHome from './auth/AuthHome';
import LoginPage from './Pages/LoginPage';  
import ProtectedRoute from './auth/ProtectedRoute';
import { useEffect, useState } from 'react';
import axios from './auth/defaultr-axios';
import { AuthProvider, useAuthContext } from './auth/Auth'; 
import RegisterPage from './Pages/RegisterPage';
 

 
function App() {  
  
  const { user, setUser,keyValue } = useAuthContext();
  const [isAuthenticated,setIsAuthenticated]= useState(false);
	// check if user is logged in or not from server
	useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem('token') ?? 'no-token';
        const resp = await axios.get('user', {
          headers: {
            'Authorization': `Bearer ${token}`,
            
          }
        });
  
        if (resp.status === 200) {
          localStorage.setItem("user",JSON.stringify(resp.data))
          console.log("User set successfully", resp.data,user);
          setIsAuthenticated(true)
        }else{
          localStorage.removeItem("user")
        }
      } catch (error) { 
        localStorage.removeItem("user")
        console.warn("Catch Error "+error);
      }
    })();
  }, []);
   
  const { any } = useParams();
  return (
    
    <ChakraProvider>
    <BrowserRouter>
     <AuthProvider> 
      <Routes>
        
          
          <Route path="/" element={<Home isAuthenticated={isAuthenticated}  />} />
          <Route
  path="login"
  element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />}
/>   
<Route
  path="register"
  element={isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterPage />}
/>
          <Route index element={<Home isAuthenticated={isAuthenticated} />} />
          <Route path="products" element={<ProductsPage isAuthenticated={isAuthenticated} />} />
          <Route path="brands" element={<BrandsPage isAuthenticated={isAuthenticated}  />} />
          <Route path="offers" element={<OffersPage isAuthenticated={isAuthenticated}  />} />
          <Route path="ekana-academy" element={<EkanaAcademy isAuthenticated={isAuthenticated}  />} />
          <Route path="start-a-bussines" element={<StartABussines  isAuthenticated={isAuthenticated} />} />
          <Route path="resource-files" element={<Resources isAuthenticated={isAuthenticated}  />} />
          
          <Route
        path="dashboard"
        element={isAuthenticated ? <AuthHome user={user} id={keyValue} isAuthenticated={isAuthenticated}  /> : <Navigate to="/login" />}
      />
           

          {/* Product Link  */}
          {/* <Route path="product/:any" element={<SingleProductFunction/>} /> */}

          {/* Importnat Links  */}
          <Route path="fssai" element={<Fssai isAuthenticated={isAuthenticated} />} />
          <Route path="iso" element={<Iso isAuthenticated={isAuthenticated} />} />
          <Route path="privacy-policy" element={<PrivacyPolicy isAuthenticated={isAuthenticated} />} />
          <Route path="refund-policy" element={<RefundPolicy isAuthenticated={isAuthenticated} />} />
          <Route path="terms-of-use" element={<TermsConditions isAuthenticated={isAuthenticated} />} />
          <Route path="user-agreement" element={<UserAgreement isAuthenticated={isAuthenticated} />} />
          <Route path="contact-us" element={<Contact isAuthenticated={isAuthenticated} />} />

          {/* Links */}
          <Route path='product/:any' element={<SingleProducts isAuthenticated={isAuthenticated} />}/>
          <Route path="about-ekana/about" element={<AboutUs isAuthenticated={isAuthenticated} />} />
          <Route path="about-ekana/start-a-bussines" element={<AboutUs isAuthenticated={isAuthenticated} />} />
          <Route path="about-ekana/our-journey" element={<AboutUs isAuthenticated={isAuthenticated} />} />
          <Route path="about-ekana/our-brand" element={<AboutUs/>} />
          <Route path="about-ekana/csr" element={<AboutUs isAuthenticated={isAuthenticated} />} />
          <Route path="about-ekana/vision" element={<AboutUs/>} />
          <Route path="about-ekana/mission" element={<AboutUs isAuthenticated={isAuthenticated} />} />
          <Route path="about-ekana/career" element={<AboutUs/>} />
          <Route path="about-ekana/faqs" element={<AboutUs isAuthenticated={isAuthenticated} />} />

          <Route path="/my-cart" element={<MyCart isAuthenticated={isAuthenticated} />}/>

          {/* quick Links  */}
          <Route path="faq" element={<Faq isAuthenticated={isAuthenticated} />}/>
          <Route path="shipping-process" element={<ShippingProcess isAuthenticated={isAuthenticated} />}/>
          
 



           
          
         
          
        
      </Routes> 
      </AuthProvider>
    </BrowserRouter>
    </ChakraProvider>
  );

   
}

export default App;

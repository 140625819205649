import React from 'react'
import ImageHeader from '../../components/ImageHeader'
import earn from '../../images/earn.jpg'
import ebo from '../../images/ebo.jpg'
import opportunities from '../../images/opportunity.jpg'
import mp from '../../images/mp.jpg'



function BussinesComponent() {
  return (
    <div>
        <div className='shaded-bg' style={{'borderRadius':'0px'}}>
        <h4>Start a Bussines</h4>
      </div>
      <ImageHeader bgColor='#ccefcb' color="#077004" text="Become an EBO" image={ebo}/>
      <div className='content__bg'>
      
      <p>
  Ekana Herbs' income plan offers a variety of bonuses and incentives for members to earn money and build their own businesses. With a focus on team building and maintaining consistent performance, members can earn up to 40% lifetime discount on Mrp, 5% repurchase power bonus, 20% team build bonus, 5% team maintain bonus and fitness assured fund, 7% accelerator bonus and 3% child education fund, 10% speed bonus, 5% lifestyle bonus, and 5% royalty club bonus. These bonuses are based on a matching system that rewards team performance and total company PV turnover, with monthly and half-yearly payout durations.
</p>

      </div>

      <ImageHeader bgColor='#ccefcb' color="#077004" text="Ekana Bussines Opportunity" image={opportunities}/>
      <div className='content__bg'>
      <p>
  Join the Ekana Herbs family and discover an opportunity to achieve financial independence while promoting health and wellness. As a member of our network marketing community, you can build your own business and share our high-quality herbal supplements with others. With a commitment to sustainability and ethical sourcing, Ekana Herbs offers a unique opportunity to make a positive impact on the world while creating the lifestyle you desire.
</p>
<p>
  As an Ekana Herbs member, you will receive training and support to help you succeed, and have access to a range of benefits including discounts, bonuses, and the opportunity to participate in our income plan. With a low start-up cost and the flexibility to work from anywhere, Ekana Herbs offers a truly empowering opportunity to transform your life.
</p>
<p>
  Don't miss out on this exciting opportunity to join the Ekana Herbs family and build the business of your dreams. Contact us today to learn more and get started!
</p>


  
  
  <ImageHeader bgColor='#ccefcb' color="#077004" text="Marketting Plan" image={mp}/>
      <div className='content__bg'>
      <p>
  Ekana Herbs' income plan offers a variety of bonuses and incentives for members to earn money and build their own businesses. With a focus on team building and maintaining consistent performance, members can earn up to 40% lifetime discount on Mrp, 5% repurchase power bonus, 20% team build bonus, 5% team maintain bonus and fitness assured fund, 7% accelerator bonus and 3% child education fund, 10% speed bonus, 5% lifestyle bonus, and 5% royalty club bonus. These bonuses are based on a matching system that rewards team performance and total company PV turnover, with monthly and half-yearly payout durations.
</p>
      </div>
      </div>
    </div>
  )
}

export default BussinesComponent

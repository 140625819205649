import React, { useEffect, useRef, useState } from 'react'
import Logo from  '../Logo/Logo.png';
import CartIcon from '../svgs/CartIcon';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons'; 
import MenuIcon from '../svgs/MenuIcon';
import UserIcon from '../svgs/UserIcon';
import { Button, CloseButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
 
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton, 
  Input, 
} from '@chakra-ui/react'
import FaceBookIcon from '../svgs/FaceBookIcon';
import Instagram from '../svgs/Instagram';
import Youtube from '../svgs/Youtube';
import { useAuthContext } from '../auth/Auth';


function MainHeader() {
  const { isOpen: isLogoutModalOpen, onOpen: onLogoutModalOpen, onClose: onLogoutModalClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');
  const signin= useAuthContext();
  
  
  const [cartQuantity,setCartQuantity]= useState('');
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const handleSubmit = (e) => {
    e.preventDefault();
    // Redirect to the products page with the search query
    window.location.href = `/products?search=${searchTerm}`;
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleLogoutAsk=()=>{
   // onClose();
    onLogoutModalOpen();
  }
  const logout=()=>{
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return window.location.href='/login';
  }
  useEffect(() => {
    // Retrieve cart items from localStorage
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    // Calculate total quantity in the cart
    const totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
    setCartQuantity(cartItems.length);
  }, [cartQuantity]);
  return (
    <div className='header__main'>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'full'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader><img src={Logo} style={{height:'50px'}}  /></DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmit}>
              <Input placeholder='Search for products ..' value={searchTerm} onChange={handleInputChange} />
            </form>
            

            <ul className='drawer_ul'>
            <li> <Link to="/products" onClick={onClose}>Products</Link>  </li>
            
            <li> <Link to="/about-ekana/about" onClick={onClose}>About Ekana</Link></li>
            <li><Link to="/offers" onClick={onClose}>Offers</Link></li>
            <li><Link to="/start-a-bussines" onClick={onClose} >Start a Bussines</Link></li>
            </ul>  
            <hr/>
            
            <div className='text-center'>
              {signin.user==null?<Link to="/login" > <Button colorScheme='gray' onClick={onClose}  >Signin</Button></Link>:(
            <Button colorScheme='gray' onClick={handleLogoutAsk}>Logout</Button>)
   } </div>
          </DrawerBody>
          <Modal isCentered isOpen={isLogoutModalOpen} onClose={onLogoutModalClose}>
      <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(10deg)'
    />
        <ModalContent>
          <ModalHeader>
            Log Out Prompt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text style={{fontWeight:'normal',fontSize:'17px'}}>Are you sure you want to logout</Text>
          </ModalBody>
          <ModalFooter>
            <Button   onClick={()=>{logout()}}>Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

          <DrawerFooter>
            <div className='text-center m-auto'>
              <strong>Join Us</strong>
              <ul className='drawer_footer_ul'>
              <li><a  target='_blank'  href='https://www.facebook.com/profile.php?id=100091429549546&mibextid=ZbWKwL'><FaceBookIcon  color="black"/></a></li>
              <li>
                <a target='_blank'   href='https://instagram.com/ekanaherbs_official?igshid=ZDdkNTZiNTM='> <Instagram  color='black'/> </a>
              </li>
             <li>
               <a target='_blank' href='https://www.youtube.com/@EkanaHerbs'><Youtube color='black'/></a>
             </li>
        
       
             </ul>
             <p style={{textAlign:'center'}}>Ekanaherbs All Rights Reserved @ 2024</p>
             <p style={{textAlign:'center'}}>Made with ❤️ in India</p>
             <hr/>
             
             <p style={{textAlign:'center'}}>Developed By : <a href='https://www.acetians.com'>Acetians Technologies</a></p>
            </div>
             
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
       <ul>
         <li>
            <ul>
        <li> <Link to="/"><img src={Logo} style={{height:'50px'}}  /></Link>   </li>
        <li> <Link to="/products">Products</Link>  </li>
        <li> <Link to="/about-ekana/about">About Ekana</Link></li>
        <li><Link to="/offers">Offers</Link></li>
         
             </ul>
         </li>
         <li>
         <ul>
        
        <li>
        <form onSubmit={handleSubmit}>
      <input
        type="search"
        placeholder='Search for Products'
        value={searchTerm}
        onChange={handleInputChange}
      />
       
    </form>
           </li>
           <li><Link to="/my-cart"> <img src='https://cdn-icons-png.flaticon.com/128/3737/3737372.png' style={{height:'25px'}} alt='cart' />  </Link> {cartQuantity > 0 && <sup style={{color:'red'}}>({cartQuantity})</sup>}  </li>
           <li><Link to="/my-cart"> <img src='https://cdn-icons-png.flaticon.com/128/3737/3737372.png' style={{height:'20px'}} alt='cart' />  </Link> {cartQuantity > 0 && <sup style={{color:'red'}}>({cartQuantity})</sup>}</li>
           <li>
           <span  ref={btnRef}    onClick={onOpen}><SearchIcon boxSize={6} /></span>
           </li>
           <li>
           <img src='https://cdn-icons-png.flaticon.com/128/3899/3899618.png' style={{height:'20px',marginInline:'5px'}} alt='user' />
           </li>
           <li>
            <span  ref={btnRef}    onClick={onOpen}><MenuIcon size  /></span>
           </li>

        
             </ul>
         </li>
       </ul>
    </div>
  )
}

export default MainHeader

import React from 'react'
import f1 from '../../src/images/f1.png'
import f2 from '../../src/images/f2.png'
import f3 from '../../src/images/f3.png'
import f4 from '../../src/images/f4.png'
import f5 from '../../src/images/f5.png'

function OurStory() {
  return (
    <div>
      <h1>Why Choose Us</h1>
      <br/>
      <div className='row d-flex justify-content-center'>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f1} style={{height:'100px'}} className='m-auto' />
         <p className='text-center fw-bolder'>Experienced Profile</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f2} style={{height:'90px'}} className='m-auto' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >Well designed competition open</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f3} style={{height:'90px'}} className='m-auto' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >We belive in  transparency</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f4} style={{height:'90px'}} className='m-auto ' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >We delivered high quality products</p>
        </div>
        <div className='col-md-2 text-center col-sm-6 p-3 comp-2'>
         <img src={f5} style={{height:'90px'}} className='m-auto' />
         <p className='text-center fw-bolder' style={{paddingInline:'20px'}} >Ekana academy work for a better future of associates</p>
        </div>
      </div>
      <br/>
    </div>
  )
}

export default OurStory

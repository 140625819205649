import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ProductItem = ({ product }) => {
  const [addedToCart, setAddedToCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);

   

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);
    const found = cartItems.find(item => item.product === product.id);
    if (found) {
      setAddedToCart(true);
    }
  }, []);
//modification above removed cartItems and product fromuse Effect array 
  const handleAddToCart = () => {
    const updatedCart = [...cartItems,{'quantity':1,'product':product.id,'type':'product'} ];
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setCartItems(updatedCart);
    setAddedToCart(true);
  };

  return (
    <div className='product__container'>
      <a href={`/product/${product.product_slug}`} >
        <img className='' src={`https://ekanaherbs.com/custom/images/products/new/${product.images.split(',')[0].replace('-','/')}`} alt="Product Image" />
        
        <p className='product__title'>{product.product_title}</p>
        <p className='product__quantity'>{product.product_quantity}{product.size_type_id}</p>
        <p className='product__code h-font'>{product.product_code}</p>
        </a>
        <br />
        <p className='product__title'>₹ {product.product_price} /-  
          {!addedToCart ? (
            <span className='product__addToCart' onClick={handleAddToCart}>
              <span className='product__addToCart__text'>Add to Cart</span>
            </span>
          ) : (
            <Link to="/my-cart">
              <span className='product__goToCart'>
                <span className='product__goToCart__text'>Go to Cart</span>
              </span>
            </Link>
          )}
        </p>
        <p className='product__brand'>Inclusive of all tax</p>
     
     
    </div>
  );
};

export default ProductItem;
